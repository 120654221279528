import moment from 'moment';

const ISO_FORMAT = "yyyy-MM-DD HH:mm:ss"
const Y2d_FORMAT = "DD.MM.yyyy"
const H2M_FORMAT = "HH:mm"

export function now() {
    return new Date();
}

export function currentTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function startOfDay(date: Date) {
    return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0) : date
}

export function nextDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, date.getHours(), date.getMinutes(), date.getSeconds());
}

export function parseServerDateTime(dateTimeString: string): Date | undefined {
    return dateTimeString ? moment.utc(dateTimeString, ISO_FORMAT).local().toDate() : undefined
}

export function formatServerDateTime(dateTime: Date | undefined): string {
    return dateTime ? moment.utc(moment(dateTime)).format(ISO_FORMAT) : ''
}

export function formatDateY2d(dateTime: Date | undefined): string {
    return dateTime ? moment(dateTime).format(Y2d_FORMAT) : ''
}

export function formatTimeIntervalHm(dateTimeFrom: Date | undefined, dateTimeTo: Date | undefined): string {
    const timeFrom = dateTimeFrom ? moment(dateTimeFrom).format(H2M_FORMAT) : '';
    const timeTo = dateTimeTo ? moment(dateTimeFrom).format(H2M_FORMAT) : '';
    return `${timeFrom} - ${timeTo}`;
}
import { createState, Downgraded, useState } from '@hookstate/core'
import { Validation } from '@hookstate/validation';
import { uploadDataWebService, fetchDataRest, downloadFile } from '../backendServices/backendServices'
import branding from "../branding/branding"
import { nextDay } from "../utils/Dates";
import { addValidators } from './ExhibitorValidation';
import { mapOrganization, parseUploadXml, mapOrganizationOut, mockShowrooms, mockCoupons } from "./ExhibitorDataMapper";
import { ServiceKeys } from "./ConfigDataState";
import { ReferenceDataState } from "./ReferenceDataState";

export const EMPTY_LINK: Link = {
    id: '',
    url: '',
    urlDe: '',
    text: '',
    textDe: '',
    order: 0
}

export const EMPTY_PRODUCT: Product = {
    id: '',
    name: '',
    nameDe: '',
    initials: '',
    orderProduct: 0,
    description: '',
    teaser: '',
    descriptionDe: '',
    teaserDe: '',
    logoUrl: '',
    logoFileId: '',
    tags: [],
    medias: [],
    categories: []
}

export const EMPTY_TRADEMARK: Trademark = {
    id: '',
    name: '',
    nameDe: '',
    logoUrl: '',
    logoFileId: '',
    initials: '',
    orderTrademark: 0,
    description: '',
    teaser: '',
    descriptionDe: '',
    teaserDe: '',
    tags: [],
    medias: [],
    categories: []
}

export const EMPTY_PERSON: Person = {
    id: '',
    isEditable: true,
    function: '',
    lastName: '',
    salutation: '',
    title: '',
    firstName: '',
    logoUrl: '',
    midName: '',
    logoFileId: '',
    initials: '',
    company: '',
    position: '',
    positionDe: '',
    email: '',
    phone: '',
    fax: '',
    web: '',
    orderPerson: 0,
    description: '',
    teaser: '',
    descriptionDe: '',
    teaserDe: ''
}

export const EMPTY_MEDIA: Media = {
    id: '',
    fileId: '',
    url: '',
    name: '',
    contentType: '',
    size: '',
    order: 0,
    title: '',
    titleDe: '',
    subtitle: '',
    subtitleDe: ''
}

export const EMPTY_NEWS: News = {
    id: '',
    name: '',
    nameDe: '',
    logoUrl: '',
    initials: '',
    date: new Date(),
    description: '',
    descriptionDe: '',
    teaser: '',
    teaserDe: '',
    tags: [],
    medias: [],
    categories: []
}

export const EMPTY_EVENTDATE: EventDate = {
    id: '',
    orderEventDates: 0,
    name: '',
    nameDe: '',
    logourl: '',
    logourlDe: '',
    startdatetime: new Date(),
    enddatetime: new Date(),
    eventName: '',
    eventNameDe: '',
    location: '',
    locationDe: '',
    type: '',
    isListable: false,
    liveStreamingPreviewUrl: '',
    breakoutAccess: false,
    description: '',
    teaser: '',
    descriptionDe: '',
    teaserDe: '',
    persons: [],
    medias: [],
    tags: []
}

export const EMPTY_SHOWROOM: Showrooms = {
    id: '',
    orderShowrooms: 0,
    title: '',
    titleDe: '',
    logoFileId: '',
    logourl: '',
    logourlDe: '',
    accessMethod: '',
    shortDescription: '',
    shortDescriptionDe: ''
}

export const EMPTY_COUPON: Coupons = {
    id: '',
    orderCoupons: 0,
    title: '',
    titleDe: '',
    logoFileId: '',
    logourl: '',
    logourlDe: '',
    description: '',
    descriptionDe: '',
    url: '',
    urlDe: '',
    startdate: new Date(),
    enddate: new Date()
}

export const EMPTY_JOB: Job = {
    id: '',
    title: '',
    titleDe: '',
    logoFileId: '',
    logoUrl: '',
    location: '',
    locationDe: '',
    employment: '',
    employmentDe: '',
    careerLevel: '',
    careerLevelDe: '',
    requirements: '',
    requirementsDe: '',
    active: true,
    startdate: new Date(),
    updated: new Date(),
    displayStart: new Date(),
    displayStop: nextDay(new Date()),
    description: '',
    descriptionDe: '',
    persons: [],
    medias: []
}

export const EMPTY_PRINTCATALOG: PrintCatalog = {
    id: '',
    logoUrl: '',
    logoFileId: '',
    nameImported: true,
    name: '',
    addressImported: true,
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    city: '',
    countryCode: '',
    contactImported: true,
    phone: '',
    email: '',
    fax: '',
    web: '',
    descriptionImported: true,
    description: '',
    descriptionDe: '',
    sortName: '',
    adPages: []
}

let initialState = {
    id: '',
    token: '',
    organization: {
        id: '',
        name: '',
        industry: '',
        city: '',
        address1: '',
        address2: '',
        address3: '',
        logoUrl: '',
        backgroundImageUrl: '',
        mobile: '',
        phone: '',
        fax: '',
        email: '',
        email2: '',
        postCode: '',
        countryCode: '',
        facebook: '',
        instagram: '',
        linkedIn: '',
        twitter: '',
        googlePlus: '',
        pinterest: '',
        xing: '',
        youTube: '',
        foundingYear: '',
        size: '',
        web: '',
        description: '',
        teaser: '',
        descriptionDe: '',
        teaserDe: '',
        tags: [],
        headerVideoUrl: '',
        headerVideoImageUrl: '',
        iframeUrl: '',
        iframeUrlDe: '',
        consentTitle: '',
        consentTitleDe: '',
        consentText: '',
        consentTextDe: '',
        links: [],
        categories: [],
        products: [],
        trademarks: [],
        persons: [],
        medias: [],
        newsList: [],
        eventDates: [],
        showrooms: [],
        coupons: [],
        jobOffers: [],
        printCatalog: EMPTY_PRINTCATALOG
    }
}

export interface Link {
    id: string
    url: string
    text: string
    textDe: string
    urlDe: string
    order: number
}

export interface Organization {
    id: string
    name: string
    industry: string
    city: string
    address1: string
    address2: string
    address3: string
    logoUrl: string
    logoFileId?: string
    backgroundImageUrl: string
    backgroundImageFileId?: string
    mobile: string
    phone: string
    fax: string
    email: string
    email2: string
    postCode: string
    countryCode: string
    facebook: string
    instagram: string
    linkedIn: string
    twitter: string
    googlePlus: string
    pinterest: string
    xing: string
    youTube: string
    foundingYear: string
    size: string
    web: string
    description: string
    teaser: string
    descriptionDe: string
    teaserDe: string
    tags: string[]
    headerVideoUrl: string
    headerVideoFileId?: string
    headerVideoImageUrl: string
    headerVideoImageFileId?: string
    iframeUrl: string
    iframeUrlDe: string
    consentTitle: string
    consentTitleDe: string
    consentText: string
    consentTextDe: string
    links: Link[]
    categories: Category[]
    products: Product[]
    trademarks: Trademark[]
    persons: Person[]
    medias: Media[]
    newsList: News[]
    eventDates: EventDate[]
    showrooms: Showrooms[]
    coupons: Coupons[]
    jobOffers: Job[]
    printCatalog: PrintCatalog
}

export interface Category {
    id: string
}

export interface Product {
    id: string
    name: string
    nameDe: string
    initials: string
    orderProduct: number
    description: string
    teaser: string
    descriptionDe: string
    teaserDe: string
    logoUrl: string
    logoFileId?: string
    tags: string[]
    medias: Media[]
    categories: Category[]
}

export interface Trademark {
    id: string
    name: string
    nameDe: string
    initials: string
    orderTrademark: number
    description: string
    teaser: string
    descriptionDe: string
    teaserDe: string
    logoUrl: string
    logoFileId?: string
    tags: string[]
    medias: Media[]
    categories: Category[]
}

export interface Tags {
    tag: string
}

export interface Media {
    id: string
    fileId: string
    url: string
    name: string
    contentType: string
    size: string
    order: number
    title: string
    titleDe: string
    subtitle: string
    subtitleDe: string
}

export interface Person {
    id: string
    isEditable: boolean
    title: string
    firstName: string
    lastName: string
    salutation: string
    midName: string
    initials: string
    function: string
    company: string
    position: string
    positionDe: string
    email: string
    phone: string
    fax: string
    web: string
    logoUrl: string
    logoFileId?: string
    orderPerson: number
    description: string
    teaser: string
    descriptionDe: string
    teaserDe: string
}

export interface News {
    id: string,
    name: string,
    nameDe: string,
    logoUrl: string,
    initials: string,
    date?: Date,
    description: string,
    descriptionDe: string,
    teaser: string,
    teaserDe: string,
    logoFileId?: string,
    tags: string[],
    medias: Media[],
    categories: Category[]
}

export interface EventDate {
    id: string,
    orderEventDates: number,
    name: string,
    nameDe: string,
    logoFileId?: string,
    logourl: string,
    logourlDe: string,
    startdatetime: Date,
    enddatetime: Date,
    eventName: string,
    eventNameDe: string,
    location: string,
    locationDe: string,
    type: string;
    isListable: boolean,
    liveStreamingPreviewUrl: string,
    liveStreamingPreviewFileID?: string,
    breakoutAccess: boolean,
    description: string,
    teaser: string,
    descriptionDe: string,
    teaserDe: string,
    persons: Person[],
    medias: Media[],
    tags: string[]
}

export interface Showrooms {
    id: string,
    orderShowrooms: number,
    title: string,
    titleDe: string,
    logoFileId?: string,
    logourl: string,
    logourlDe: string,
    accessMethod: string,
    shortDescription: string,
    shortDescriptionDe: string
}

export interface Coupons {
    id: string,
    orderCoupons: number,
    title: string,
    titleDe: string,
    logoFileId?: string,
    logourl: string,
    logourlDe: string,
    description: string,
    descriptionDe: string,
    url: string,
    urlDe: string,
    startdate: Date,
    enddate: Date
}

export interface Showrooms {
    id: string,
    orderShowrooms: number,
    title: string,
    titleDe: string,
    logoFileId?: string,
    logourl: string,
    logourlDe: string,
    accessMethod: string,
    shortDescription: string,
    shortDescriptionDe: string
}

export interface Coupons {
    id: string,
    orderCoupons: number,
    title: string,
    titleDe: string,
    logoFileId?: string,
    logourl: string,
    logourlDe: string,
    description: string,
    descriptionDe: string,
    url: string,
    urlDe: string,
    startdate: Date,
    enddate: Date
}

export interface Job {
    id: string
    title: string
    titleDe: string
    logoFileId?: string
    logoUrl: string
    location: string
    locationDe: string
    employment: string
    employmentDe: string
    careerLevel: string
    careerLevelDe: string
    requirements: string
    requirementsDe: string
    active: boolean
    startdate?: Date
    updated?: Date
    displayStart?: Date
    displayStop?: Date
    description: string
    descriptionDe: string
    persons: Person[]
    medias: Media[]
}

export interface PrintAdPage {
    id: string
    adUrl: string
    adFileId?: string
    name: string
}
export interface PrintCatalog {
    id: string

    logoUrl: string
    logoFileId?: string

    nameImported: boolean
    name: string

    addressImported: boolean
    address1: string
    address2: string
    address3: string

    postcode: string
    city: string
    countryCode: string

    contactImported: boolean
    phone: string
    fax: string
    email: string
    web: string

    descriptionImported: boolean
    description: string
    descriptionDe: string

    sortName: string

    adPages: Array<PrintAdPage>
}

export interface ExhibitorData {
    id: string
    token: string
    organization: Organization
}

export interface UploadResult {
    fileId: string
    fileUrl: string
    fileName: string
    contentType: string
    size: string
}

const exhibitorDatastate = createState<ExhibitorData>(initialState)
exhibitorDatastate.attach(Validation)

async function loadExhibitorData(exhibitorId: string, accessToken: string) {
    const params: any = {
        "accessToken": accessToken
    }
    return await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/${exhibitorId}`, params, "GET")
}

async function storeExhibitorData(exhibitorId: string, accessToken: string, organization: Organization, serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const params: any = {
        "accessToken": accessToken,
    }
    return await fetchDataRest(`/selfservice/0/${branding.configuration.topicName}/${exhibitorId}`, params, "POST", mapOrganizationOut(organization, serviceKeys, refDataState))
}

async function uploadExhibitorImage(exhibitorId: string, accessToken: string, uploadPurpose: string, image: File, onProgress: (progress: number) => void) {
    const uploadResponse = await uploadDataWebService(`/uploadfile?accessToken=${accessToken}&uploadPurpose=${uploadPurpose}`, image, onProgress)
    return parseUploadXml(uploadResponse)
}

async function downloadExhibitorImage(path: string, filename: string) {
    downloadFile(path, filename)
}

export function useExhibitorDataState() {
    const state = useState(exhibitorDatastate)

    return ({
        init(exhibitorId: string, accessToken: string, serviceKeys: ServiceKeys) {
            const exData = loadExhibitorData(exhibitorId, accessToken)
            exData.then(
                (result) => {
                    const organization = mapOrganization(result)
                    state.set({ id: exhibitorId, token: accessToken, organization: { ...organization, mobile: '', showrooms: mockShowrooms(), coupons: mockCoupons() } })
                    addValidators(exhibitorDatastate, serviceKeys)
                    return result
                })
            return exData
        },

        reinit(serviceKeys: ServiceKeys) {
            const exData = loadExhibitorData(state.id.get(), state.token.get())
            exData.then(
                (result) => {
                    const organization = mapOrganization(result)
                    state.set({ id: state.id.get(), token: state.token.get(), organization: { ...organization, mobile: '', showrooms: mockShowrooms(), coupons: mockCoupons() } })
                    addValidators(exhibitorDatastate, serviceKeys)
                    return result
                })
            return exData
        },

        save(serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
            return storeExhibitorData(state.id.get(), state.token.get(), state.attach(Downgraded).get().organization, serviceKeys, refDataState)
        },

        upload(uploadPurpose: string, image: File, onProgress: (progress: number) => void) {
            return uploadExhibitorImage(state.id.get(), state.token.get(), uploadPurpose, image, onProgress)
        },

        download(path: string, filename: string) {
            return downloadExhibitorImage(path, filename)
        },

        getOrganization() {
            return state.organization
        },

        validateField(fieldName: string) {
            return state.promised ? [] : Validation(exhibitorDatastate.organization.nested(fieldName as keyof Organization)).errors()
        },

        getLinks() {
            return state.organization.links
        },

        getCategories() {
            return state.organization.categories
        },

        getProducts() {
            return state.organization.products
        },

        getTrademarks() {
            return state.organization.trademarks
        },

        getPersons() {
            return state.organization.persons
        },

        getMedias() {
            return state.organization.medias
        },

        getNews() {
            return state.organization.newsList
        },

        getEventDates() {
            return state.organization.eventDates
        },

        getShowrooms() {
            return state.organization.showrooms
        },

        getCoupons() {
            return state.organization.coupons
        },

        getPrintCatalog() {
            return state.organization.printCatalog
        },

        getJobs() {
            return state.organization.jobOffers
        }
    })
}
import { none, State } from "@hookstate/core";
import { Validation } from "@hookstate/validation";
import { isNullOrEmpty } from "../utils/Arrays";
import { ServiceKeys } from "./ConfigDataState";
import { Coupons, EMPTY_COUPON, EMPTY_EVENTDATE, EMPTY_LINK, EMPTY_MEDIA, EMPTY_NEWS, EMPTY_PERSON, EMPTY_PRODUCT, EMPTY_SHOWROOM, EMPTY_TRADEMARK, EMPTY_JOB, EventDate, ExhibitorData, Link, Media, News, Person, Product, Showrooms, Trademark, Job, PrintCatalog } from "./ExhibitorDataState";

//const PHONE_REGEX = new RegExp('^[\\d+-// ]*$')

const EMAIL_REGEX = new RegExp("^[A-Za-z0-9](.*)([@]{1})(.{1,})(\\.)(.{1,})")

// const DATETIME_REGEX = new RegExp("(19|20)[0-9]{2}-(0|1)[0-9]-[0-3][0-9] 0[0-9]|1[0-9]|2[0123]:[012345][0-9]:[012345][0-9]")

function fixEmpty<T>(stateArray: State<T[]>, addValidation: (state: State<T[]>) => void, emptyElement: T) {
    const isEmpty = isNullOrEmpty(stateArray.get())
    if (isEmpty) {
        stateArray.merge([emptyElement])
    }
    addValidation(stateArray)
    if (isEmpty) {
        setTimeout(() => {
            stateArray[0].set(none)
        }, 0);
    }
}

export function addValidators(exhibitorDatastate: State<ExhibitorData>, serviceKeys: ServiceKeys) {
    Validation(exhibitorDatastate.organization.name).validate((name: string) => !!name && name.length > 0, 'missingCompanyName')
    Validation(exhibitorDatastate.organization.address1).validate((address1: string) => !!address1 && address1.length > 0, 'missingCompanyAddress')
    Validation(exhibitorDatastate.organization.postCode).validate((postCode: string) => !!postCode && postCode.length > 0, 'missingCompanyPostcode')
    Validation(exhibitorDatastate.organization.city).validate((city: string) => !!city && city.length > 0, 'missingCompanyCity')
    Validation(exhibitorDatastate.organization.countryCode).validate((countryCode: string) => !!countryCode && countryCode.length > 0, 'missingCompanyCountry')


    //Validation(exhibitorDatastate.organization.mobile).validate((mobile: string | undefined) => !mobile || PHONE_REGEX.test(mobile), 'Please enter a valid mobile number')
    //Validation(exhibitorDatastate.organization.phone).validate((phone: string | undefined) => !phone || PHONE_REGEX.test(phone), 'Please enter a valid phone number')
    //Validation(exhibitorDatastate.organization.fax).validate((fax: string | undefined) => !fax || PHONE_REGEX.test(fax), 'Please enter a valid fax number')
    Validation(exhibitorDatastate.organization.email).validate((email: string | undefined) => !email || EMAIL_REGEX.test(email), 'invalidCompanyEmail')
    Validation(exhibitorDatastate.organization.email2).validate((email2: string | undefined) => !email2 || EMAIL_REGEX.test(email2), 'invalidCompanyEmail2')
    if (serviceKeys.link && serviceKeys.link > 0) {
        fixEmpty(exhibitorDatastate.organization.links, addValidatorsLinks, EMPTY_LINK)
    }
    if (serviceKeys.products && serviceKeys.products > 0) {
        fixEmpty(exhibitorDatastate.organization.products, addValidatorsProducts, EMPTY_PRODUCT)
    }
    if (serviceKeys.trademarks && serviceKeys.trademarks > 0) {
        fixEmpty(exhibitorDatastate.organization.trademarks, addValidatorsTrademarks, EMPTY_TRADEMARK)
    }
    if (serviceKeys.contactPartner && serviceKeys.contactPartner > 0) {
        fixEmpty(exhibitorDatastate.organization.persons, addValidatorsTeams, EMPTY_PERSON)
    }
    if (serviceKeys.multimedia && serviceKeys.multimedia) {
        fixEmpty(exhibitorDatastate.organization.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    }
    if (serviceKeys.news && serviceKeys.news > 0) {
        fixEmpty(exhibitorDatastate.organization.newsList, addValidatorsNews, EMPTY_NEWS)
    }

    const eventDatesVisible = ((serviceKeys.eventDate ? serviceKeys.eventDate > 0 : false)
        || (serviceKeys.eventDateMasterClass ? serviceKeys.eventDateMasterClass > 0 : false)
        || (serviceKeys.eventDateRemoteLiveStream ? serviceKeys.eventDateRemoteLiveStream > 0 : false)
        || (serviceKeys.eventDatePreRecorded ? serviceKeys.eventDatePreRecorded > 0 : false)
        || (serviceKeys.eventDateRoundTable ? serviceKeys.eventDateRoundTable > 0 : false))

    if (eventDatesVisible) {
        fixEmpty(exhibitorDatastate.organization.eventDates, addValidatorsEventDates, EMPTY_EVENTDATE)
    }
    if ((serviceKeys.showroomPublic !== undefined && serviceKeys.showroomPublic > 0) || (serviceKeys.showroomPrivate !== undefined && serviceKeys.showroomPrivate > 0)) {
        fixEmpty(exhibitorDatastate.organization.showrooms, addValidatorsShowrooms, EMPTY_SHOWROOM)
    }
    if (serviceKeys.goodies && serviceKeys.goodies > 0) {
        fixEmpty(exhibitorDatastate.organization.coupons, addValidatorsCoupons, EMPTY_COUPON)
    }
    if (serviceKeys.jobAd && serviceKeys.jobAd > 0) {
        fixEmpty(exhibitorDatastate.organization.jobOffers, addValidatorsJobs, EMPTY_JOB)
    }
    const printVisible =
        (serviceKeys.printOrganizationName ? serviceKeys.printOrganizationName > 0 : false)
        || (serviceKeys.printAddress ? serviceKeys.printAddress > 0 : false)
        || (serviceKeys.printContact ? serviceKeys.printContact > 0 : false)
        || (serviceKeys.printLogo ? serviceKeys.printLogo > 0 : false)
        || (serviceKeys.printDescription ? serviceKeys.printDescription > 0 : false)
        || (serviceKeys.printAd ? serviceKeys.printAd > 0 : false)

    if (printVisible) {
        addValidatorsPrintCatalog(exhibitorDatastate.organization.printCatalog, serviceKeys)
    }
}

export function addValidatorsLinks(links: State<Link[]>) {
    links.forEach(link => {
        Validation(link.text).validate((text: string) => !!text && text.length > 0, 'missingLinkName')
        Validation(link.textDe).validate((text: string) => !!text && text.length > 0, 'missingLinkName')
    })
}

export function addValidatorsProducts(products: State<Product[]>) {
    products.forEach(product => {
        Validation(product.name).validate((name: string) => !!name && name.length > 0, 'missingProductName')
        Validation(product.nameDe).validate((name: string) => !!name && name.length > 0, 'missingProductName')
        fixEmpty(product.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    })
}

export function addValidatorsTrademarks(trademarks: State<Trademark[]>) {
    trademarks.forEach(trademark => {
        Validation(trademark.name).validate((name: string) => !!name && name.length > 0, 'missingTrademarkName')
        fixEmpty(trademark.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    })
}

export function addValidatorsTeams(persons: State<Person[]>) {
    persons.forEach(person => {
        Validation(person.firstName).validate((firstName: string) => !!firstName && firstName.length > 0, 'missingFirstName')
        Validation(person.lastName).validate((lastName: string) => !!lastName && lastName.length > 0, 'missingLastName')
        Validation(person.email).validate((email: string) => !!email && email.length > 0, 'missingEmailName')
        Validation(person.email).validate((email: string) => !email || EMAIL_REGEX.test(email), 'invalidEmail')
        Validation(person.function).validate((role: string) => !!role && role.length > 0, 'missingRole')
    })
}

export function addValidatorsMultimedia(medias: State<Media[]>) {
    medias.forEach(media => {
        Validation(media.title).validate((title: string) => !!title && title.length > 0, 'missingMediaTitle')
        Validation(media.titleDe).validate((title: string) => !!title && title.length > 0, 'missingMediaTitle')
    })
}

export function addValidatorsNews(newsList: State<News[]>) {
    newsList.forEach(news => {
        Validation(news.name).validate((name: string) => {
            return !!name && name.length > 0
        }, 'missingNewsTitle')
        Validation(news.nameDe).validate((name: string) => !!name && name.length > 0, 'missingNewsTitle')
        Validation(news.date).validate((date: Date | undefined) => !!date, 'missingNewsDate')
        fixEmpty(news.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    })
}

export function addValidatorsEventDates(eventDates: State<EventDate[]>) {
    eventDates.forEach(eventDate => {
        Validation(eventDate.name).validate((name: string) => !!name && name.length > 0, 'missingEventDateTitle')
        Validation(eventDate.nameDe).validate((name: string) => !!name && name.length > 0, 'missingEventDateTitle')
        fixEmpty(eventDate.persons, addValidatorsTeams, EMPTY_PERSON)
        fixEmpty(eventDate.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    })
}

export function addValidatorsShowrooms(showrooms: State<Showrooms[]>) {
    showrooms.forEach(showroom => {
        Validation(showroom.title).validate((title: string) => !!title && title.length > 0, 'missingShowroomTitle')
        Validation(showroom.titleDe).validate((title: string) => !!title && title.length > 0, 'missingShowroomTitle')
    })
}

export function addValidatorsCoupons(coupons: State<Coupons[]>) {
    coupons.forEach(coupon => {
        Validation(coupon.title).validate((title: string) => !!title && title.length > 0, 'missingCouponTitle')
        Validation(coupon.titleDe).validate((title: string) => !!title && title.length > 0, 'missingCouponTitle')
    })
}

export function addValidatorsJobs(jobs: State<Job[]>) {
    jobs.forEach(job => {
        Validation(job.title).validate((title: string) => !!title && title.length > 0, 'missingJobTitle')
        Validation(job.titleDe).validate((titleDe: string) => !!titleDe && titleDe.length > 0, 'missingJobTitle')
        fixEmpty(job.persons, addValidatorsTeams, EMPTY_PERSON)
        fixEmpty(job.medias, addValidatorsMultimedia, EMPTY_MEDIA)
    })
}

export function addValidatorsPrintCatalog(printCatalog: State<PrintCatalog>, serviceKeys: ServiceKeys) {
    if (serviceKeys.printOrganizationName && serviceKeys.printOrganizationName > 0) {
        Validation(printCatalog.name).validate((companyName: string) => !!companyName && companyName.length > 0, 'missingPrintCompanyName')
    }
    if (serviceKeys.printAddress && serviceKeys.printAddress > 0) {
        Validation(printCatalog.address1).validate((address: string) => !!address && address.length > 0, 'missingPrintAddress')
        Validation(printCatalog.postcode).validate((postcode: string) => !!postcode && postcode.length > 0, 'missingPrintZipcode')
        Validation(printCatalog.city).validate((city: string) => !!city && city.length > 0, 'missingPrintCity')
        Validation(printCatalog.countryCode).validate((country: string) => !!country && country.length > 0, 'missingPrintCountry')
    }
    if (serviceKeys.printContact && serviceKeys.printContact > 0) {
        Validation(printCatalog.email).validate((email: string | undefined) => !email || EMAIL_REGEX.test(email), 'invalidEmail')
    }
}